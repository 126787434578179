import { POSITION, COLORS, FONTS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

const SERVICE_ITEM_HEIGHT = 480;

export default StyleSheet.create({

  servicesWrapper: {
    position: POSITION.RELATIVE,
    width: '100%',
    height: 'auto',
    padding: '40px 0 80px',
    textAlign: 'center',

    ':before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      opacity: .1,
      zIndex: 1,
      pointerEvents: 'none',
      background: 'url(../images/services-background.jpg) center center no-repeat',
      backgroundSize: 'cover',
    },

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: '100%',
      height: 80,
      bottom: 0,
      left: 0,
      zIndex: 2,
      transform: 'rotateZ(0deg)',
      transformOrigin: '100% 0 0',
      background: `linear-gradient(to top left, ${COLORS.WHITE} 50%, ${COLORS.TRANSPARENT} 51%)`,

      '@media (max-width: 768px)': {
        display: 'none',
      },

    },

    '@media (max-width: 640px)': {
      padding: '40px 0 0',
    },

  },

  serviceWrapperInner: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
    padding: '20px 10px',
    zIndex: 10,
    ...FONTS.OPEN_SANS,
    textAlign: 'left',
  },

  serviceItem: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flex: '0 1 33.33%',
    height: SERVICE_ITEM_HEIGHT,
    padding: 10,
    color: COLORS.WHITE,
    overflow: 'hidden',
    boxSizing: 'border-box',

    '@media (max-width: 1000px)': {
      flex: '0 1 33%',
    },

    '@media (max-width: 900px)': {
      flex: '0 1 50%',
    },

    '@media (max-width: 640px)': {
      flex: '0 1 100%',
      height: 440,
    },

  },

  serviceItemInner: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: 10,

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 2,
      pointerEvents: 'none',
      background: 'rgba(0, 0, 0, .7)',
      transition: 'background .4s linear',
    },

    '@media (max-width: 640px)': {
      padding: '10px 20px',
    },

  },

  serviceItemInnerHover: {

    ':after': {
      background: COLORS.PINK,
    },

  },

  serviceBg: {
    position: POSITION.ABSOLUTE,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    objectFit: 'cover',
  },

  serviceInfoWrapper: {
    position: POSITION.ABSOLUTE,
    left: 10,
    right: 10,
    bottom: 5,
    padding: '30px 10px 10px',

    '@media (max-width: 640px)': {
      padding: '20px 10px 10px',
    },

  },

  serviceInfo: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 11,

    '@media (max-width: 640px)': {
      fontSize: 14,
    },

  },

  serviceBody: {
    position: POSITION.ABSOLUTE,
    top: '50%',
    left: 10,
    right: 10,
    zIndex: 20,
    transform: 'translateY(-50%)',
    transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  },

  serviceBodyHover: {
    position: POSITION.ABSOLUTE,
    top: 20,
    transform: 'translateY(0%)',
    transition: 'top .5s linear, transform .5s linear',
    transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  },

  servicesTitle: {
    position: POSITION.RELATIVE,
    display: 'inline-block',
    ...FONTS.OPEN_SANS,
    fontSize: 24,
    fontWeight: 500,
    padding: '0 0 10px',
    marginBottom: 10,
    textAlign: 'center',
    color: COLORS.GRAY2,

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      height: 1,
      bottom: 0,
      left: 0,
      right: 0,
      background: COLORS.PINK,
    },

    '@media (max-width: 640px)': {
      fontSize: 20,
    },

  },

  serviceTitle: {
    position: POSITION.RELATIVE,
    zIndex: 10,
    ...FONTS.OPEN_SANS,
    fontSize: 18,
    fontWeight: 700,
    padding: '0 0 10px',
    textAlign: 'center',
    color: COLORS.WHITE,

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      height: 1,
      bottom: 0,
      left: 10,
      right: 10,
      zIndex: 2,
      pointerEvents: 'none',
      background: COLORS.WHITE,
    },

    '@media (max-width: 640px)': {
      fontSize: 16,
    },

  },

  serviceLead: {
    position: POSITION.RELATIVE,
    zIndex: 10,
    padding: 10,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.4,
    textTransform: 'uppercase',
    textAlign: 'center',
    color: COLORS.WHITE,

    '@media (max-width: 640px)': {
      fontSize: 11,
    },

  },

  serviceDescription: {
    position: POSITION.RELATIVE,
    zIndex: 10,
    height: 0,
    opacity: 0,
    padding: '0 10px',
    fontSize: 13,
    lineHeight: 1.2,
    ...FONTS.OPEN_SANS,
    overflow: 'hidden',
    transition: 'height .5s, opacity .5s',
    transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',

    ':nth-child(1n) > p': {
      marginBottom: 10,
    },

    ':nth-child(1n) > p > b': {
      fontWeight: 700,
    },

    ':nth-child(1n) > ul': {
      marginBottom: 4,
      padding: '0 0 4px 0',
    },

    ':nth-child(1n) > ul li': {
      position: POSITION.RELATIVE,
      paddingLeft: 8,
      marginBottom: 1,
    },

    ':nth-child(1n) > ul li:before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 4,
      height: 4,
      top: 5,
      left: 0,
      background: COLORS.WHITE,
    },

  },

  serviceDescriptionHover: {
    height: 340,
    opacity: 1,
  },

  serviceLogo: {
    position: POSITION.RELATIVE,
    width: 24,
    height: 24,
    marginRight: 5,
    stroke: 'none',

    '@media (max-width: 640px)': {
      width: 20,
      height: 20,
    },

  },

  serviceTime: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: '1 1 50%',
    padding: 0,
    whiteSpace: 'nowrap',

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 1,
      top: 0,
      bottom: 0,
      right: 0,
      zIndex: 2,
      background: COLORS.WHITE,
    },

  },

  servicePrice: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: '1 1 50%',
    padding: 0,
    whiteSpace: 'nowrap',
  },

});
