import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import ServiceItem from './ServiceItem';
import s from './Services.style';

class Services extends Component {
  state = {};

  render() {
    return (
      <div className={ css(s.servicesWrapper) }>
        <h2 className={ css(s.servicesTitle) }>Szolgáltatásaim</h2>
        <div className={ css(s.serviceWrapperInner) }>
          <ServiceItem
            image={ 'images/services/alkalmi-smink.jpg' }
            title={ 'Alkalmi smink' }
            lead={ 'Hivatalos eseményre, buliba' }
            description={
              <React.Fragment>
                <p>Fontos eseményre készülsz? Esküvőre vagy hivatalos? Bulizni mentek a csajokkal?
                   Csak pár olyan alkalom, amikor jól jönne egy profi smink.</p>
                <p>Alkalmi smink kategóriába tartozik:</p>
                <ul>
                  <li>Nappali smink</li>
                  <li>Esti smink</li>
                  <li>Szalagavató smink</li>
                  <li>Smink fotózásra kiszállás nélkül</li>
                  <li>Smink fitness versenyre kiszállás nélkül</li>
                </ul>
                <p>A műszempilla felhelyezése feláras.</p>
              </React.Fragment>
            }
            time={ '1 óra' }
            price={ '20 000 Ft' }
          />
          <ServiceItem
            image={ 'images/services/eskuvo.jpg' }
            title={ 'Esküvői smink csomag' }
            lead={ 'Konzultáció, próbasmink' }
            description={
              <React.Fragment>
                <p>Mit tartalmaz?</p>
                <ul>
                  <li>Részletes konzultációt</li>
                  <li>Próbasminket, ami garanciális</li>
                  <li>Sminked az esküvő napján</li>
                  <li>Vőlegény arcának lemattítását</li>
                </ul>
                <p>További feláras szolgáltatások:</p>
                <ul>
                  <li>Vendégek sminkje - 15e Ft/fő</li>
                  <li>Műszempilla felhelyezés - 3-8e Ft/fő</li>
                  <li>Smink kreatív fotózásra - 20e Ft</li>
                  <li>Készenléti felár fotózásra - 5e Ft/óra</li>
                  <li>Budapest területén kiszállás - 5e Ft (2 extra smink igény esetén ingyenes)
                  </li>
                  <li>Vidéki kiszállás csak abban az esetben lehetséges, ha minimum 4 extra sminkre
                      van igény
                      és ebben az esetben 100 Ft/km a kiszállás díja.
                  </li>
                </ul>
                <p>A szolgáltatás időtartama a létszámtól és a megrendelt szolgáltatásoktól
                   függ.</p>
                <p>További részletekért keress privát üzenetben.</p>
              </React.Fragment>
            }
            time={ '1,5-3 óra ' }
            price={ '60 000 Ft' }
          />
          <ServiceItem
            image={ 'images/services/sminktanacsadas.jpg' }
            title={ 'Egyéni sminktanácsadás és oktatás' }
            lead={ 'Személyre szabva, tippek és trükkök' }
            description={
              <React.Fragment>
                <p>Maximálisan személyre van szabva, nem én mondom meg, hogy mit fogsz tanulni,
                   hanem Te mondod meg nekem, hogy mit szeretnél elsajátítani. Az oktatást követően
                   részletes összefoglalót kapsz a tanultakról, minden apró trükkről.</p>
                <p>Ha szeretnél jobban / gyorsabban / tudatosabban sminkelni, szeretnél olyan
                   trükköket eltanulni, amivel megkönnyíted a reggeli készülődést vagy szeretnél
                   kicsit jobban odafigyelni a megjelenésedre, az arcápolási rutinodra, akkor
                   az <b>Egyéni sminktanácsadás</b> Neked való.
                </p>
                <p>További részletekért keress privát üzenetben.</p>
              </React.Fragment>
            }
            time={ '3-4 óra' }
            price={ '45 000 Ft' }
          />
          <ServiceItem
            image={ 'images/services/csoportos.jpg' }
            title={ 'Csoportos tanácsadás' }
            lead={ 'Tanácsadás' }
            description={
              <React.Fragment>
                <p>Átfogó elméleti szépségápolási és sminktanácsadás, ami tökéletes program lehet
                   lánybúcsúra, csapatépítőre, csajos programnak.</p>
                <p>A szolgáltatás időtartama 2-4 óra között a létszámtól függően.</p>
                <p>1-4 fő között a tanácsadás ára 15.000 Ft/fő, 5 főtől pedig 12.000 Ft/fő</p>
                <p>Mi van az árban?</p>
                <ul>
                  <li>Összefoglaló a tanultakról</li>
                  <li>Welcome drink</li>
                  <li>Nasi</li>
                </ul>
              </React.Fragment>
            }
            time={ '2-4 óra ' }
            price={ '12e-15e Ft / fő' }
          />
          <ServiceItem
            image={ 'images/services/muszempilla.jpg' }
            title={ 'Műszempilla felhelyezés' }
            lead={ 'Minőségi, természetes' }
            description={
              <React.Fragment>
                <p>Kizárólag minőségi, természetes, kényelmes szempillákat használok.</p>
                <p>A pilla dobozát minden esetben odaadom, így többször is fel lehet használni
                   őket.</p>
              </React.Fragment>
            }
            time={ '15 perc ' }
            price={ '3e-8e Ft' }
          />

          <ServiceItem
            image={ 'images/services/ajandekutalvany.jpg' }
            title={ 'Ajándékutalvány' }
            lead={ 'Ajándékozz élményt' }
            description={
              <React.Fragment>
                <p>Minden szolgáltatás megvásárolható ízléses, névre szóló ajándékutalvány
                   formájában.</p>
                <p>A legjobb döntés élményt ajándékozni valakinek, nem egy újabb porfogót a
                   polcra.</p>
              </React.Fragment>
            }
            time={ '' }
            price={ '' }
          />
        </div>
      </div>
    );
  }
}

export default Services;
