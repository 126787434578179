import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import HeroImage from '../components/HeroImage';
import s from './Services.style';

class Services extends Component {
  state = {};

  render() {
    return (
      <section>
        <HeroImage
          title={ 'Szolgáltatásaim' }
          image={ [process.env.PUBLIC_URL + '/images/services-hero.jpg'] }
        />
        <div className={ css(s.contentWrapper) }>

          {/* Alkalmi smink */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Alkalmi smink</h2>
              <h6>Hivatalos eseményre, buliba</h6>
              <span className={css(s.contentInfo)}>20 000 Ft</span>
              <p>
                Fontos eseményre készülsz? Esküvőre vagy hivatalos? Bulizni mentek a csajokkal? Csak
                pár olyan alkalom, amikor jól jönne egy profi smink.</p>
              <p>Alkalmi smink kategóriába tartozik:</p>
              <ul>
                <li>Nappali smink</li>
                <li>Esti smink</li>
                <li>Szalagavató smink</li>
                <li>Smink fotózásra kiszállás nélkül</li>
                <li>Smink fitness versenyre kiszállás nélkül</li>
              </ul>
              <p>A műszempilla felhelyezése feláras.</p>
            </div>
          </div>

          {/* Eskuvoi smink */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Esküvői smink csomag</h2>
              <h6>Konzultáció, próbasmink</h6>
              <span className={css(s.contentInfo)}>1,5 - 3 óra / 60 000 Ft</span>
              <p>Mit tartalmaz?</p>
              <ul>
                <li>Részletes konzultációt</li>
                <li>Próbasminket a stúdiómban (30 000 Ft)</li>
                <li>Budapest területén kiszállást</li>
                <li>Sminked az esküvő napján (30 000 Ft)</li>
                <li>Vőlegény arcának lemattítását</li>
              </ul>
              <p>További feláras szolgáltatások:</p>
              <ul>
                <li>Vendégek sminkje - 15 000 Ft/fő</li>
                <li>Műszempilla felhelyezés - 3 000 - 8 000 Ft között/fő</li>
                <li>Smink kreatív fotózásra - 20 000 Ft</li>
                <li>Készenléti felár fotózásra - 5000 Ft/óra</li>
                <li>Budapest területén kiszállás - 5000 Ft (2 extra smink igény esetén ingyenes)</li>
                <li>Vidéki kiszállás csak abban az esetben lehetséges, ha minimum 4 extra sminkre
                    van igény és ebben az esetben 100 Ft/km a kiszállás díja.
                </li>
              </ul>
              <p>A szolgáltatás időtartama a létszámtól és a megrendelt szolgáltatásoktól
                 függ.</p>
              <p>További részletekért keress privát üzenetben.</p>
            </div>
          </div>

          {/* Egyeni smink */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Egyéni sminktanácsadás és oktatás</h2>
              <h6>Személyre szabva, tippek és trükkök</h6>
              <span className={css(s.contentInfo)}>3 - 4 óra / 45 000 Ft</span>
              <p>Maximálisan személyre van szabva, nem én mondom meg, hogy mit fogsz tanulni,
                 hanem Te mondod meg nekem, hogy mit szeretnél elsajátítani. Az oktatást követően
                 részletes összefoglalót kapsz a tanultakról, minden apró trükkről.</p>
              <p>Ha szeretnél jobban / gyorsabban / tudatosabban sminkelni, szeretnél olyan
                 trükköket eltanulni, amivel megkönnyíted a reggeli készülődést vagy szeretnél
                 kicsit jobban odafigyelni a megjelenésedre, az arcápolási rutinodra, akkor
                 az <b>Egyéni smink-<br />tanácsadás</b> Neked való.
              </p>
              <p>További részletekért keress privát üzenetben.</p>
            </div>
          </div>

          {/* Csoportos tanacsadas */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Csoportos tanácsadás</h2>
              <h6>Tudásfrissítés</h6>
              <span className={css(s.contentInfo)}>2 - 4 óra / 12 000 - 15 000 Ft / fő</span>
              <p>Átfogó szépségápolási és sminktanácsadás, ami tökéletes program lehet
                 lánybúcsúra, csapatépítőre, csajos programnak.</p>
              <p>A szolgáltatás időtartama 2-4 óra között a létszámtól függően.</p>
              <p>1-4 fő között a tanácsadás ára 15.000 Ft/fő, 5 főtől pedig 12.000 Ft/fő</p>
              <p>Mi van az árban?</p>
              <ul>
                <li>Összefoglaló a tanultakról</li>
                <li>Welcome drink</li>
                <li>Nasi</li>
              </ul>
            </div>
          </div>

          {/* Muszempilla */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Műszempilla felhelyezés</h2>
              <h6>Smink, kiszállás</h6>
              <span className={css(s.contentInfo)}>15 perc / 3 000 - 8 000 Ft</span>
              <p>Kizárólag minőségi, természetes, kényelmes szempillákat használok.</p>
              <p>A pilla dobozát minden esetben odaadom, így többször is fel lehet használni
                 őket.</p>
            </div>
          </div>

          {/* Ajandekutalvany */}
          <div className={ css([s.contentBlock, s.contentBlockWithoutDecor]) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Ajándékutalvány</h2>
              <h6>Ajándékozz élményt</h6>
              <span className={css(s.contentInfo)}></span>
              <p>Minden szolgáltatás megvásárolható ízléses, névre szóló ajándékutalvány
                 formájában.</p>
              <p>A legjobb döntés élményt ajándékozni valakinek, nem egy újabb porfogót a
                 polcra.</p>
            </div>
          </div>

        </div>
      </section>
    );
  }
}

export default Services;
